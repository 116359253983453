
import { request, gql } from 'graphql-request'

async function fetchData () {
  const endpoint = process.env.GATSBY_API_URL

  try {
    const res = await request(endpoint, query)
    return res
  } catch (error) {
    console.error('Erro ao buscar dados da API GraphQL:', error)
    return error
  }
}

export default fetchData

const query = gql`
        query {
            isBlackDays

            blackFriday {
              startDate
              endDate
            }

            bannersSite {
              initialDate
              finalDate
              id
              title
              firstElement {
                link
                imageMobile
                image
                alt
              }
              secondElement {
                link
                image
                imageMobile
                alt
                rawHtml
              }
            }

            plans {
              id
              name
              description
              bestOption
              hasSupport
              icon
              accessTimeInMonths
              totalValue
              numberOfInstallments
              valueOfInstallment
              order
              hasEssay
              monthlyCredits
              utm
              valueWithPixDiscount
            }
          }
      `
