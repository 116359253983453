import { Container } from 'components/container/container'
import { Title } from 'components/title/title'
import React, { useRef, useState } from 'react'
import styled from 'styled-components'
import Slider from 'react-slick'
import { useWindowSize, media } from 'resources'
import t from 'prop-types'
import SlickDots from './components/SlickDots'

import '../slick/slick-theme.css'
import '../slick/slick.css'
import { sortByField } from 'resources/helpers/sort-helper'

import images from './images'

export const Teachers = ({ teachers, isBlackFridayHome }) => {
  const { width } = useWindowSize()
  const isSliderInitialized = useRef(false)
  const [currentSlide, setCurrentSlide] = useState(0)
  const [isChangingSlide, setIsChangingSlide] = useState(false)
  const [isHoveringSlide, setIsHoveringSlide] = useState(false)
  const data = isBlackFridayHome ? images : teachers
  const settings = {
    dots: false,
    arrows: false,
    infinite: data.length > 5,
    speed: 500,
    slidesToShow: 5,
    slidesToScroll: 1,
    centerMode: false,
    beforeChange: (current, next) => {
      setIsChangingSlide(true)
      setCurrentSlide(next)
    },
    afterChange: () => setIsChangingSlide(false),
    responsive: [
      {
        breakpoint: 750,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  }

  const sliderRef = useRef(null)

  sortByField(data, 'viewOrder')

  React.useEffect(() => {
    const isMobile = width && width <= 700

    if (!isSliderInitialized.current && isMobile) {
      isSliderInitialized.current = true

      if (isMobile && data?.[2] !== undefined) {
        sliderRef.current?.slickGoTo(2)
      }
    }
  }, [data, width])

  return (
    <Box isBlackFridayHome={isBlackFridayHome}>
      <Container>
        <Wrapper>
          <Content>
            <Title size='sm' color='white'>
              Os<Text isBlackFridayHome={isBlackFridayHome}> melhores professores da internet&nbsp;</Text>à sua disposição
            </Title>
            <SliderWrapper>
              <LowOpacityDiv isHidden={isChangingSlide || isHoveringSlide} isBlackFridayHome={isBlackFridayHome} />
              <Slider ref={sliderRef} {...settings}>
                {data.map((teacher, teacherIndex) => (
                  <Teacher teacherIndex={teacherIndex} key={teacherIndex} isBlackFridayHome={isBlackFridayHome}>
                    <div
                      onMouseEnter={() => setIsHoveringSlide(true)}
                      onMouseLeave={() => setIsHoveringSlide(false)}
                    >
                      <img
                        id={`teacher-${teacherIndex}`}
                        src={isBlackFridayHome ? teacher.center : teacher.siteAvatar}
                        alt={teacher.name}
                      />
                      <Biography className='biography'>
                        {!isBlackFridayHome &&
                          <BiographyTitle>
                            Professor(a) {teacher.name}
                          </BiographyTitle>}
                        {!isBlackFridayHome ? teacher.biography : (
                          <img
                            id={`teacher-${teacherIndex}`}
                            src={teacher.hover}
                            alt={teacher.name}
                          />
                        )}
                      </Biography>
                    </div>
                  </Teacher>
                ))}
              </Slider>
            </SliderWrapper>
            <SlickDots
              sliderRef={sliderRef}
              slideCount={data.length}
              sliderSettings={settings}
              currentSlide={currentSlide}
            />
          </Content>
        </Wrapper>
      </Container>
    </Box>
  )
}

Teachers.propTypes = {
  teachers: t.array,
  isBlackFridayHome: t.bool,
}

const Box = styled.section`
  background: ${({ isBlackFridayHome, theme }) => (isBlackFridayHome ? theme.blackFridayTheme.background : 'rgb(0, 45, 154)')};
  ${({ isBlackFridayHome, theme }) => {
    if (isBlackFridayHome) {
      return `
      background: linear-gradient(to right, ${theme.colors.primary} 0%, ${theme.blackFridayTheme.background} 55%)`
    }
    return `
    background: linear-gradient(
      20deg,
      rgba(0, 45, 154, 1) 0%,
      rgba(1, 74, 255, 1) 100%
    );`
}}
`
const Text = styled.span`
  /* color: ${({ isBlackFridayHome, theme }) => isBlackFridayHome ? theme.blackFridayTheme.primary : '#00eaa9'}; */
  text-shadow: ${({ theme, isBlackFridayHome }) =>
    isBlackFridayHome
      ? `0 0 5px ${theme.blackFridayTheme.neon},
         0 0 5px ${theme.blackFridayTheme.neon},
         0 0 5px ${theme.blackFridayTheme.neon},
         0 0 5px ${theme.blackFridayTheme.neon}`
      : 'none'};
`
const Wrapper = styled.div`
  max-width: 120rem;
  width: 100%;
  margin: 0 auto;
  padding: 8rem 1.5rem;
`

const Content = styled.div`
  position: relative;
  z-index: 1;
  display: flex;
  flex-direction: column;
  gap: 5rem;
`

const Teacher = styled.div`
  position: relative;
  padding: 0.25rem;
  img {
    width: ${({ isBlackFridayHome }) => (isBlackFridayHome ? '230px' : '100%')};
    border-radius: 0.5rem;

    ${media.lessThan('smd')`
    width: ${({ isBlackFridayHome }) => (isBlackFridayHome ? '320px' : '100%')};
  `}
  }
  &:hover .biography {
    visibility: visible;
    background-color: rgba(0, 0, 0, 0.5);
    backdrop-filter: blur(2px);
    border-radius: 0.5rem;
    ${({ isBlackFridayHome }) => {
      if (isBlackFridayHome) {
        return`
          width: 230px;
          right: 3%;`
      }
    }}
  }
`

const Biography = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  padding: 1rem;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: background-color 200ms;
  visibility: hidden;
`

const BiographyTitle = styled.div`
  color: ${({ theme }) => theme.colors.accent};
  font-weight: bold;
  font-size: 1.2em;
`

const SliderWrapper = styled.div`
  position: relative;
`

const LowOpacityDiv = styled.div`
  pointer-events: none;
  position: absolute;
  top: 0;
  left: 0;
  height: calc(100% - 2.75rem);
  width: calc(100% - 5px);
  margin-top: 12px;
  margin-left: 3px;
  border-radius: 4px;
  z-index: 10;

  transition: opacity 200ms;

  opacity: ${({ isHidden }) => (isHidden ? '0' : '1')};

  ${media.lessThan('md')`
    opacity: 0;
  `}
`
