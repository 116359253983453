import ALENCAR1 from './imgs/ALENCAR-1.png'
import ALENCAR2 from './imgs/ALENCAR-2.png'
import ALENCAR3 from './imgs/ALENCAR-3.png'
import BOARO1 from './imgs/BOARO-1.png'
import BOARO2 from './imgs/BOARO-2.png'
import BOARO3 from './imgs/BOARO-3.png'
import CAJU1 from './imgs/CAJU-01.png'
import CAJU2 from './imgs/CAJU-02.png'
import CAJU3 from './imgs/CAJU-03.png'
import COELHO1 from './imgs/COELHO-1.png'
import COELHO2 from './imgs/COELHO-2.png'
import COELHO3 from './imgs/COELHO-3.png'
import FERRETTO1 from './imgs/FERRETTO-1.png'
import FERRETTO2 from './imgs/FERRETTO-2.png'
import FERRETTO3 from './imgs/FERRETTO-3.png'
import GROTH1 from './imgs/GROTH-1.png'
import GROTH2 from './imgs/GROTH-2.png'
import GROTH3 from './imgs/GROTH-3.png'
import JOAO1 from './imgs/JOAO-1.png'
import JOAO2 from './imgs/JOAO-2.png'
import JOAO3 from './imgs/JOAO-3.png'
import LANDIM1 from './imgs/LANDIM-1.png'
import LANDIM2 from './imgs/LANDIM-2.png'
import LANDIM3 from './imgs/LANDIM-3.png'
import MARCONDES1 from './imgs/MARCONDES-1.png'
import MARCONDES2 from './imgs/MARCONDES-2.png'
import MARCONDES3 from './imgs/MARCONDES-3.png'
import MICHEL1 from './imgs/MICHEL-1.png'
import MICHEL2 from './imgs/MICHEL-2.png'
import MICHEL3 from './imgs/MICHEL-3.png'
import PEDRO1 from './imgs/PEDRO-1.png'
import PEDRO2 from './imgs/PEDRO-2.png'
import PEDRO3 from './imgs/PEDRO-3.png'
import SAMUEL1 from './imgs/SAMUEL-1.png'
import SAMUEL2 from './imgs/SAMUEL-2.png'
import SAMUEL3 from './imgs/SAMUEL-3.png'
import TANAY1 from './imgs/TANAY-1.png'
import TANAY2 from './imgs/TANAY-2.png'
import TANAY3 from './imgs/TANAY-3.png'
import VERIDIANE1 from './imgs/VERIDIANE-1.png'
import VERIDIANE2 from './imgs/VERIDIANE-2.png'
import VERIDIANE3 from './imgs/VERIDIANE-3.png'

const images = [
  {
    center: BOARO1,
    side: BOARO2,
    hover: BOARO3,
  },
  {
    center: MICHEL1,
    side: MICHEL2,
    hover: MICHEL3,
  },
  {
    center: FERRETTO1,
    side: FERRETTO2,
    hover: FERRETTO3,
  },
  {
    center: COELHO1,
    side: COELHO2,
    hover: COELHO3,
  },
  {
    center: LANDIM1,
    side: LANDIM2,
    hover: LANDIM3,
  },
  {
    center: CAJU1,
    side: CAJU2,
    hover: CAJU3,
  },
  {
    center: GROTH1,
    side: GROTH2,
    hover: GROTH3,
  },
  {
    center: PEDRO1,
    side: PEDRO2,
    hover: PEDRO3,
  },
  {
    center: SAMUEL1,
    side: SAMUEL2,
    hover: SAMUEL3,
  },
  {
    center: MARCONDES1,
    side: MARCONDES2,
    hover: MARCONDES3,
  },
  {
    center: ALENCAR1,
    side: ALENCAR2,
    hover: ALENCAR3,
  },
  {
    center: VERIDIANE1,
    side: VERIDIANE2,
    hover: VERIDIANE3,
  },
  {
    center: TANAY1,
    side: TANAY2,
    hover: TANAY3,
  },
  {
    center: JOAO1,
    side: JOAO2,
    hover: JOAO3,
  },
]

export default images
