import React, { useContext } from 'react'
import styled from 'styled-components'
import t from 'prop-types'
import { faqContent, media, theme } from 'resources'
import { Container } from 'components/container/container'
import { Question } from 'components/question/question'
import { Title as BaseTitle } from 'components/title/title'
import BlackFridayContext from 'resources/contexts/BlackFridayContext'

export const MainFaq = ({ blackFridayData }) => {
  const isBlackFriday = useContext(BlackFridayContext)
  const questions = [...faqContent]

  if (isBlackFriday && blackFridayData) {
    const startDate = new Date(blackFridayData.startDate).toLocaleDateString()
    const endDate = new Date(blackFridayData.endDate).toLocaleDateString()

    questions.unshift({
      title: 'Como funciona a Black Days?',
      content: `A Black Days é a Black Friday do Professor Ferretto, que ocorre de ${startDate} a ${endDate}.
      Adquirindo o plano de acesso de 12 meses, você ganha 1 mês extra, levando, assim, 13 meses de acesso pelo preço de 12 meses,
      com desconto. Adquirindo o plano anual durante o período da Black Days, você poderá fazer uso da garantia condicional de cashback,
      ou seja, passando em uma universidade pública através do SISU 2025/1, utilizando a nota do ENEM 2024, você poderá solicitar o
      cancelamento da compra e terá a devolução total do valor pago pelo plano anual. Em resumo, para que a garantia de cashback
      possa ser exigida, os seguintes critérios deverão ser atendidos:
      <ul>
        <li>1. Ter adquirido o plano de acesso de 12 + 1 mês (13 meses de acesso) entre os dias 01/11/2024 e 30/11/2024;</li>
        <li>2. Comprovar aprovação em alguma instituição pública de ensino de nível superior, exclusivamente através do Sistema de Seleção Unificada - SISU, 2025/1, com a utilização da nota do ENEM 2024;</li>
        <li>3. Apresentar o comprovante de matrícula na respectiva instituição pública de ensino de nível superior, sendo a mesma realizada no máximo até a data de 30/03/2025;</li>
        <li>4. Solicitar o cancelamento e a garantia de cashback, por meio do e-mail de suporte <a href='mailto:contato@professorferretto.com.br'>contato@professorferretto.com.br</a>, no máximo até o dia 30/03/2025.</li>
      </ul>
      Acesse os <a href="https://www.professorferretto.com.br/politica-de-privacidade#termos-de-uso">Termos de Uso</a> da plataforma para visualizar, de forma detalhada, as condições de utilização da garantia condicional de cashback.
      `,
    })
  }

  return (
    <Box isBlackFriday={isBlackFriday}>
      <Container>
        <Wrapper>
          <SectionHeader>
            <Title
              style={{
                color: isBlackFriday
                  ? theme.blackFridayTheme.textLight
                  : theme.colors.primary,
              }}
            >
              Perguntas frequentes
            </Title>
            <FerrettoPictureWrapper>
              <img
                // src={isBlackFriday ? 'faq-black-friday.png' : 'faq.png'}
                src='faq.png'
                alt='Professor Ferretto'
              />
            </FerrettoPictureWrapper>
          </SectionHeader>
          <QuestionsWrapper>
            {questions.slice(0, 3).map(({ title, content }, index) => (
              <Question
                key={index}
                title={`${index + 1}. ${title}`}
                content={content}
                isBlackFriday={isBlackFriday}
              />
            ))}
          </QuestionsWrapper>
        </Wrapper>
      </Container>
    </Box>
  )
}

MainFaq.propTypes = {
  blackFridayData: t.object,
}

const Box = styled.section`
  background: ${({ isBlackFriday, theme }) =>
    isBlackFriday ? theme.blackFridayTheme.background : 'rgb(247, 247, 247)'};
`

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 3rem;

  max-width: 120rem;
  width: 100%;
  margin: 0 auto;
  padding: 8rem 0.5rem;

  ${media.lessThan('xsm')`
    padding: 8rem 0rem;
  `}
`

const SectionHeader = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 3rem;
`

const Title = styled(BaseTitle)`
  margin: 0;
`

const QuestionsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
`

const FerrettoPictureWrapper = styled.div`
  width: 120px;
  height: 120px;
  min-width: 120px;
  min-height: 120px;

  img {
    width: 100%;
    height: 100%;
    border-radius: 50%;
    object-fit: cover;
  }

  ${media.lessThan('xsm')`
    width: 90px;
    height: 90px;
    min-width: 90px;
    min-height: 90px;
  `}
`
